import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';

import { PageFooter } from 'components/page-footer';
import { PageHeader } from 'components/page-header';

import { LayoutProps } from '../types';

export function GeneralLayout({ children }: LayoutProps) {
  return (
    <Layout>
      <PageHeader />
      <Layout.Content className="bg-white px-5 sm:px-[50px] py-5 sm:py-10">
        {children ?? <Outlet />}
      </Layout.Content>
      <PageFooter />
    </Layout>
  );
}
