import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { GeneralLayout } from 'components/layouts/general-layout';
import { NotFoundError } from 'components/placeholder-errors/not-found-error';
import { GeneralLayoutLoading } from 'components/placeholder-loadings/general-layout-loading';
import { PrivateRoute } from 'components/private-route';
import { lazyNamedExport } from 'utils/lazy-named-export';

const AuthRoutes = lazyNamedExport(
  import(/* webpackChunkName: "AuthRoutes" */ './Auth'),
  'AuthRoutes'
);
const LegalAssistant = lazyNamedExport(
  import(/* webpackChunkName: "LegalAssistant" */ 'pages/legal-assistant'),
  'LegalAssistant'
);
const LegalAssistantSearch = lazyNamedExport(
  import(
    /* webpackChunkName: "LegalAssistantSearch" */ 'pages/legal-assistant-search'
  ),
  'LegalAssistantSearch'
);
const LegalAssistantDetail = lazyNamedExport(
  import(
    /* webpackChunkName: "LegalAssistantDetail" */ 'pages/legal-assistant-detail'
  ),
  'LegalAssistantDetail'
);
const SettingRoutes = lazyNamedExport(
  import(/* webpackChunkName: "SettingRoutes" */ './Setting'),
  'SettingRoutes'
);

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route
        path="/*"
        element={
          <Suspense fallback={<GeneralLayoutLoading />}>
            <Routes>
              <Route path="/" element={<GeneralLayout />}>
                <Route path="" element={<LegalAssistant />} />
                <Route path="/search" element={<LegalAssistantSearch />} />
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/legal-assistant/:id"
                    element={<LegalAssistantDetail />}
                  />
                </Route>

                <Route path="setting/*" element={<SettingRoutes />} />
                <Route path="*" element={<NotFoundError />} />
              </Route>
            </Routes>
          </Suspense>
        }
      />
    </Routes>
  );
};
